import React from 'react';

import Landing from './Landing';
import { createTheme } from '@mui/material';
import red from '@mui/material/colors/red';


function App() {

  const theme = createTheme( {
    palette: {
    primary: {
      main: '#0057b8'
    },
    secondary: red
  }
});

  return (
    <div className="App">
      <Landing theme={theme} />
    </div>
  );
}

export default App;
