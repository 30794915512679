import { default as React, useContext } from 'react';

import { AppBar, Toolbar, IconButton, Typography, Button  } from '@mui/material'


export function Header(props:any) {

	const styles = {
		root: {
			flexGrow: 1,
		},
		grow: {
			flexGrow: 1,
			color:"white"
		},
		menuButton: {
			marginLeft: -12,
			marginRight: 20,
		},
		logo:{
			height:24
		},
		heading:{
			color:"white"
		}
	};

	return <AppBar position="static" style={styles.root}>
		<Toolbar>
			<span style={styles.grow}><Typography variant="h6" style={styles.heading}>Elektrotech</Typography></span>
	
		</Toolbar>
	</AppBar>



}