import { Button, Container, Grid, Theme, Typography } from '@mui/material';
import React from 'react';


interface HeroI{
    theme:Theme;
}

export function Hero(props:HeroI){ 

    const {theme} = props;

    const styles = {
        logo: {
            width:150
        } as React.CSSProperties,
        logoHolder:{
            textAlign:"center",
            margin: theme.spacing(4),
        } as React.CSSProperties,
        heroContent: {
          backgroundColor: theme.palette.background.paper,
          padding: theme.spacing(8, 0, 6),
        },
        heroButtons: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4)
        },       
        headerHolder:{
          textAlign:"center",
          margin: theme.spacing(4),
      } as React.CSSProperties,
      header:{
        height:60
    } as React.CSSProperties,
      };

	return <React.Fragment>
		<div style={styles.heroContent}>
			<Container maxWidth="md">
				<div style={styles.logoHolder}><img src={"/logo.svg"} style={styles.logo} /></div>
        <div style={styles.headerHolder}><img src={"/text.svg"} style={styles.header} /></div>
    		<Typography variant="h5" align="center" color="textSecondary" paragraph>
					Providing the metering industry with expert knowledge,<br />
					specialised technical services and an on demand workforce.<br />
				</Typography>
			
			</Container>
		</div>


	</React.Fragment>
	
}