import React, { useContext } from 'react';

import { Theme , Container, Grid, Typography} from '@mui/material';

import {styles} from "./Classes";


interface WhatWeOfferI{
	theme:Theme;
}

export function WhatWeOffer(props:WhatWeOfferI) {

	const {theme} = props;

	let classes = styles(theme);


	let imgStyle = {
		width:"60%",
		margin:"auto",
		display:"block"
	}

	return <React.Fragment>
		<Container maxWidth="lg">
			<div style={classes.section} >
			<Grid container spacing={10}>
				<Grid item md={12} xs={12}>
				
						<Typography variant="body1" >Elektrotech provides consultancy services specialising in electricity metering activities in the Australian National Market. Specializing in supporting prospective MPB/MDP's and entities seeking AEMO accreditation through to go live and periodic audit preparation.</Typography>
                        <Typography variant="body1" >
                            <ul>
                                <li>Smart meter installation and exchange</li>
                                <li>Maintenance and inspections</li>
                                <li>CT Commissioning, Testing and validation</li>
                                <li>Technical consulting services</li>
                                <li>Field compliance auditing</li>
                                <li>Training and competency assessment</li>
                                <li>Responsible person/Technical nominate</li>
                                <li>Technical system and product review</li>
                            </ul>
                            </Typography>
							<Typography variant="body1" >If you would like more information you may contact us at <a href="mailto:kevinbrown@elektrotech.com">kevinbrown@elektrotech.com.au</a></Typography>
                    	<br/>
						
				</Grid>
			</Grid>

			</div>
		</Container>
	</React.Fragment>

}