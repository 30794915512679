import { Theme } from '@mui/material';


let styles = (theme:Theme) => ({	
	section: {
		padding: "60px 20px 60px 20px",
	},
	altSection:{
        padding: "5px 0px 0px 0px",
        margin:0,
		backgroundColor:theme.palette.primary.main,
		color:theme.palette.primary.contrastText
	},
	heading:{
		marginBottom:40
	},
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
		display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6)
    },
});

export {styles};

//export default classes;
