import { Theme } from '@mui/material';
import React from 'react';
import {Footer} from '../ui/Footer';
import { Header } from '../ui/Header';
//import {GlobalContext} from '../../ui/withGlobalContext';

// import {Header} from '../../ui/Header'
// import Footer from "../../ui/Footer";
import {Hero} from './Hero'
import {WhatWeOffer} from './WhatWeOffer';
// import Splash from './Splash'


interface LandingI{
    theme:Theme
}

export default function Landing(props:LandingI){
    const {theme} = props;
	return <React.Fragment>
        <Header />
	    <Hero theme={theme} />
        <WhatWeOffer theme={theme} />
		<Footer theme={theme} />
	</React.Fragment>  
}


/*

		<Header />
			<Hero />
			<WhatWeOffer />
			<Splash />
		<Footer />
        */