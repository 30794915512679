
import { Theme } from '@mui/material';
import { AppBar, Toolbar, IconButton, Typography, Button, Grid,CardActions,CardContent,CardMedia,Card, Container  } from '@mui/material';
import React, { useContext } from 'react';

interface FooterI{
    theme:Theme;
}

export function Footer(props:FooterI){
    
    const {theme} = props;

    let styles = {
        footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
        }
    }

     return <footer style={styles.footer}>
            <Typography variant="subtitle1" align="center" color="textSecondary" component="p">Elektrotech 2021 - Melbourne, Australia</Typography>
        </footer>

}

